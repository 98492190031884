<template>
<v-data-table
    :headers="headers"
    :items="quotationLineItems"
    item-key="id"
    sort-by="id"
    sort-desc
    class="elevation-1"
    :loading="loading"
    loading-text="Loading... Please wait"
    :single-expand="true"
    :expanded.sync="expanded"
    show-expand
    @current-items="currentItems"
    :hide-default-footer="true"
    disable-pagination
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Quotation Line</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog2"
          ref="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="gray" class="mb-2" v-on="on"
              >New Line</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle2 }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.description"
                      label="Description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.place"
                      label="Place"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.qty"
                      label="QTY"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.unit_price"
                      label="Unit Price"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.discount"
                      label="Discount"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"
                >Cancel</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="save(items)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.unit_price="{ item }"
      >$ {{ item.unit_price }}</template
    >
    <template v-slot:item.discount="{ item }"
      >{{ item.discount }} %</template
    >
    <template v-slot:item.subtotal="{ item }"
      >$ {{ calcSubtotal(item) }}</template
    >
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        v-b-tooltip.hover
        title="Edit"
        >mdi-pencil</v-icon
      >
      <v-icon
        small
        @click="deleteItem(item, items)"
        v-b-tooltip.hover
        title="Delete"
        >mdi-delete</v-icon
      >
    </template>
    <template slot="footer">
      <table>
        <tr
          class="border border-right-0 border-left-0 border-bottom-0 text-center"
        >
          <td colspan>
            <v-chip color="lighten-1 mr-4">
              Quotation Total:
              <strong class="ml-2"
                >$ {{ totalAmount }}</strong
              >
            </v-chip>
          </td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import genericService from "./../../../services/generic.service";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri: "quotationslines",
    quotationLineItems: [],
    today: new Date().toISOString().substr(0, 10),
    formNewtItem: true,
    dialog: false,
    dialog2: false,
    dialog3: false,
    dialog4: false,
    search: "",
    expanded: [],
    singleExpand: false,
    popDatepick1: false,
    editedIndex: -1,
    editedIndex2: -1,
    editedItem: {
      id: "",
      description: "",
      place: "",
      qty: "",
      unit_price: "",
      discount: "",
      subtotal: "",
    },
    defaultItem: {
      id: "",
      description: "",
      place: "",
      qty: "",
      unit_price: "",
      discount: "",
      subtotal: "",
    },
    switch1: true,
    switch2: false,
    switch3: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "ID", value: 0 },
      { text: "Work Order", value: 1 },
      { text: "Vessel", value: 2 },
      { text: "Port", value: 3 },
      { text: "To", value: 4 },
      { text: "O/B", value: 5 },
      { text: "Notes", value: 6 }
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0,
    totalAmount: 0
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Quotation" : "Edit Quotation";
    },
    formTitle2() {
      return this.editedIndex2 === -1 ? "New Line" : "Edit Line";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },
    ...mapGetters([ 'currentUser' ])
  },

  props: [
    "headers", 
    "headers_extra", 
    "items", 
    "items_extra", 
    "items_extra_new", 
    "search_tool", 
    "current_quotation_id"
  ],

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    },
    dialog3(val) {
      val || this.close();
    }
  },

  methods: {
    editItem(item) {
      this.formNewtItem = false;
      this.dialog2 = true;
      this.editedIndex = this.quotationLineItems.indexOf(item);
      this.editedItem = item;
    },

    seeItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog3 = true;
    },

    calcSubtotal(item) {
      let subtotalResult =
        (item.unit_price - (item.discount * item.unit_price) / 100) * item.qty;
      item.subtotal = subtotalResult;
      this.calcTotal();
      return subtotalResult.toFixed(2);
    },

    calcTotal() {
      this.totalAmount = this.quotationLineItems.reduce((a, b) => a + b.subtotal, 0);
      return this.formatAmount(parseFloat(this.totalAmount).toFixed(2));
    },

    deleteItem(item, list) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = list.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      this.dialog2 = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedIndex2 = -1;
      }, 300);
    },

    close2() {
      /* Listado de Dialogs */
      this.dialog4 = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedIndex2 = -1;
      }, 300);
    },

    save(items) {
      var _self = this;
      _self.editedItem.subtotal = _self.calcSubtotal(_self.editedItem);
      
      if (_self.editedIndex > -1) {
        items[_self.editedIndex] = _self.editedItem;
        genericService.updateRecord("/"+_self.uri+"/" + _self.editedItem.id, _self.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      } else {
        _self.editedItem.parent_id = _self.current_quotation_id;
        _self.editedItem.parent_type = "quotations";

        _self.quotationLineItems.push(_self.editedItem);
        genericService.createRecord("/"+_self.uri, _self.editedItem, function(rta){
          if(rta != undefined) {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
            _self.editedItem.id = rta.id;
          }
        });

      }
      this.close();
    },

    formatAmount(nr) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      });

      return formatter.format(nr);
    },

    /* Filtros & Loading */
    currentItems(item) {
      this.loading = true;
      setTimeout(() => {
        this.totalTime = item.reduce((a, b) => a + (b["time"] || 0), 0);
        /* Filtros */
        this.itemsFiltered = item.length;
        this.loading = false;
      }, 300);
    },
    filterItems(itemFiltered) {
      if (itemFiltered) {
        if (itemFiltered == 99) {
          this.headers.forEach(header => (header.filterable = true));
        } else {
          this.headers.forEach(header => (header.filterable = false));
          this.headers[itemFiltered].filterable = true;
        }
      } else {
        this.headers.forEach(header => (header.filterable = true));
      }
      this.dialogFilters = false;
      return this.headers;
    },
    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },

    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },

    formNewItem(){
      this.formNewtItem = true;
      setTimeout(() => {
        this.focusField(this.$refs.formFirstField);
      }, 200)
    },
    
  },

  mounted() {
    if(this.$route.params.id == "new"){
      setTimeout(() => {
        this.dialog = true;
        this.formNewItem();
      }, 700);
    }else{
      if(this.search_tool == true){
        this.focusField(this.$refs.search);
      }
    }
  },

  created() {
    var _self = this;
    genericService.getRelatedList('quotationslines', 'quotations', _self.current_quotation_id, function(records){
      _self.quotationLineItems = records;
    });
  },

};
</script>
