<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <QuotationsList
            :headers="headersQuotations"
            :headers_extra="headersQuotationsExtras"
            :items="itemsQuotations"
            :items_extra="itemsQuotationsExtras"
            :items_extra_new="itemsQuotationsExtrasNew"
            @initialize="initialize"
            :search_tool="true"
          ></QuotationsList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import QuotationsList from "@/views/pages/quotations/QuotationsList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    QuotationsList,
  },

  data: () => ({
    uri: "quotations",
    headersQuotations: [
      { text: "ID", sortable: true, value: "id", filterable: true },
      { text: "WO", value: "work_order", sortable: false, filterable: true },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      { text: "Port", value: "port", sortable: false, filterable: true },
      { text: "To", value: "to", sortable: false, filterable: true },
      { text: "O/B", value: "ob", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersQuotationsExtras: [
      { text: "Description", value: "description", sortable: false },
      { text: "Place", value: "place", sortable: false },
      { text: "QTY", value: "qty", sortable: false },
      { text: "Unit Price", value: "unit_price", sortable: false },
      { text: "Discount", value: "discount", sortable: false },
      { text: "Subtotal", value: "subtotal", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsQuotations: [],
    itemsQuotationsExtras: [],
    itemsQuotationsExtrasNew: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      genericService.getRecordListWithLimit(
        "/" + this.uri,
        100,
        "date",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsQuotations = records;
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Quotations" }]);
  },
  computed: {
      ...mapGetters(["currentUser"]),
  },
};
</script>
